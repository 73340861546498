import axios from "axios";
import { useState, useEffect } from "react";
import Layout from '../../components/Layout.component';
import {
    Input,
    FormLabel,
    InputGroup,
    Select,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faBuilding, faCheck, faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import PageLayout from '../../components/PageLayout.component';
import ScreenAlert from '../../components/Alert/Alert.component';
import { useParams } from "react-router-dom";

export default function EditUser() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [permission, setPermission] = useState('')
    const [company, setCompany] = useState('')
    const [cnpj, setCnpj] = useState('')

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const { id } = useParams()

    async function updateUser(e) {
        e.preventDefault()
        setError(null)
        setSuccess(null)

        if (!name || !email || !permission) {
            return setError("Por favor, preencha todas as informações")
        }

        const updatedUser = { name, email, permission }

        const response = await axios.patch(`/api/user/${id}`, updatedUser, { withCredentials: true })

        try {
            setLoading(true)

            if (response) {
                setSuccess("Dados do usuário alterados com sucesso")
            }
        } catch (err) {
            setSuccess(null)
            setError("Houve um erro ao alterar os dados do usuário, tente novamente")
        }

        setLoading(false)
    }

    useEffect(() => {
        const fetchUser = async () => {
            const response = await axios.get(`/api/user/${id}`, { withCredentials: true })

            if (response) {
                setName(response.data.name)
                setEmail(response.data.email)
                setPermission(response.data.permission)
                setCompany(response.data.company)
                setCnpj(response.data.cnpj)
            }
        }

        fetchUser()
    }, [id])

    return (
        <PageLayout>
            <Layout
                editIcon={true}
                editUrl="/panel/user"
                title="Meu perfil"
            >
                <section className="flex flex-col lg:flex-row justify-between items-center lg:items-start">
                    <main className="bg-white rounded-lg drop-shadow-lg md:w-[82vw] w-[345px] py-8 px-10">
                        {error && <ScreenAlert status="error" message={error} />}
                        {success && <ScreenAlert status="success" message={success} />}

                        <form onSubmit={updateUser}>
                            {/* Dados do usuário */}
                            <div className="flex-row justify-evenly">
                                <div className="">
                                    <h2 className="text-lg font-bold">Dados do usuário</h2>

                                    <div className="flex flex-col md:flex-row">
                                        <InputGroup className="flex flex-col my-2 mr-2">
                                            <FormLabel>
                                                <FontAwesomeIcon icon={faUser} className="mr-2" />
                                                Nome
                                            </FormLabel>

                                            <Input
                                                type='text'
                                                disabled
                                                placeholder="Digite aqui seu nome"
                                                size="sm"
                                                onChange={(e) => setName(e.target.value)}
                                                value={name}
                                                borderRadius="6px"
                                            />
                                        </InputGroup>

                                        <InputGroup className="flex flex-col my-2 lg:ml-2">
                                            <FormLabel>
                                                <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                                                Email
                                            </FormLabel>
                                            <Input
                                                type='email'
                                                placeholder="Digite aqui seu email"
                                                size="sm"
                                                onChange={(e) => setEmail(e.target.value)}
                                                value={email}
                                                borderRadius="6px"
                                            />
                                        </InputGroup>
                                    </div>
                                </div>

                                {/* Dados empresariais */}
                                <div className="flex flex-col md:flex-row">
                                    <InputGroup className="flex flex-col my-2 mr-2">
                                        <FormLabel>
                                            <FontAwesomeIcon icon={faBuilding} className="mr-2" />
                                            Empresa
                                        </FormLabel>

                                        <Input
                                            type="text"
                                            disabled
                                            placeholder="Digite o nome da empresa do usuário externo"
                                            size="sm"
                                            onChange={(e) => setCompany(e.target.value)}
                                            value={company}
                                        />
                                    </InputGroup>

                                    <InputGroup className="flex flex-col my-2 lg:ml-2">
                                        <FormLabel>
                                            <FontAwesomeIcon icon={faCircleInfo} className="mr-2" />
                                            CNPJ
                                        </FormLabel>

                                        <Input
                                            type="text"
                                            disabled
                                            placeholder="Digite o CNPJ da empresa do usuário externo"
                                            size="sm"
                                            onChange={(e) => setCnpj(e.target.value)}
                                            value={cnpj}
                                        />
                                    </InputGroup>
                                </div>
                            </div>

                            <InputGroup className="flex flex-col my-2">
                                <FormLabel>
                                    <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
                                    Nível de acesso
                                </FormLabel>

                                <Select placeholder="Selecione o nível de permissão do usuário" size="sm" onChange={(e) => setPermission(e.target.value)} value={permission} borderRadius="6px">
                                    <option value="externo">Externo (Cliente)</option>
                                    <option value="operador">Operador</option>
                                    <option value="gerente">Gerente</option>
                                    <option value="administrador">Administrador</option>
                                </Select>
                            </InputGroup>

                            <div className="flex flex-col md:flex-row justify-center md:justify-end">
                                <button
                                    type="submit"
                                    className="my-2 px-3 py-2 bg-green-500 border-[1px] text-white font-semibold rounded-md drop-shadow-lg hover:bg-white hover:border-green-500 hover:text-green-500 transition-all ease-in-out duration-300"
                                    disabled={loading}
                                >
                                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                    Alterar dados do usuário
                                </button>
                            </div>
                        </form>

                    </main>
                </section>
            </Layout>
        </PageLayout>
    )
}