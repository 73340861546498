import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from "react-auth-kit";
import { HashRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AuthProvider 
            authType={'cookie'}
            authName={'_auth'}
            cookieDomain={window.location.hostname}
            cookieSecure={window.location.protocol === "https:"}
        >
            <HashRouter>
                <ChakraProvider>
                    <App />
                </ChakraProvider>
            </HashRouter>
        </AuthProvider>
    </React.StrictMode>
);