import axios from "axios";
import { FormLabel, Input, InputGroup, Select } from "@chakra-ui/react";
import { faCheck, faFolderOpen, faFolderTree, faHeader, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ScreenAlert from "../../components/Alert/Alert.component";
import Layout from "../../components/Layout.component";
import PageLayout from "../../components/PageLayout.component";

// SunEditor
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

export default function EditArticle() {
    const [category, setCategory] = useState([]);
    const [subcategory, setSubcategory] = useState([]);

    const [title, setTitle] = useState('');
    const [relatedToCategory, setRelatedToCategory] = useState('')
    const [relatedToSubcategory, setRelatedToSubcategory] = useState('')
    const [userPermission, setUserPermission] = useState('');
    const [articleContent, setArticleContent] = useState('');

    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [loading, setLoading] = useState(false)

    const { id } = useParams()

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await axios.get('/api/category', { withCredentials: true })

            if (response) {
                setCategory(response.data)
            }
        }

        const fetchSubcategories = async () => {
            if (!relatedToCategory) return

            const response = await axios.get(`/api/category/${relatedToCategory}/subcategories`, { withCredentials: true })

            if (response) {
                setSubcategory(response.data)
            }
        }

        const fetchArticle = async () => {
            const response = await axios.get(`/api/article/${id}`, { withCredentials: true })

            if (response) {
                setTitle(response.data.title)
                setRelatedToCategory(response.data.relatedToCategory)
                setRelatedToSubcategory(response.data.relatedToSubcategory)
                setUserPermission(response.data.userPermission)
                setArticleContent(response.data.articleContent)
            }
        }

        fetchCategories()
        fetchSubcategories()
        fetchArticle()
    }, [id, relatedToCategory])

    const handleChange = (content) => {
        setArticleContent(content)
    }

    const updateArticle = async (e) => {
        e.preventDefault()
        setError(null)

        if (!title || !relatedToCategory || !relatedToSubcategory || !userPermission || !articleContent) {
            return setError("Por favor, preencha todas informações necessárias")
        }

        const article = { title, relatedToCategory, relatedToSubcategory, userPermission, articleContent }

        const response = await axios.patch(`/api/article/${id}`, article, { withCredentials: true })

        try {
            setLoading(true)

            if (response.ok) { }
            setTitle("")
            setRelatedToCategory("")
            setRelatedToSubcategory("")
            setUserPermission("")
            setArticleContent("")
            setError(null)
            setSuccess("Artigo atualizado com sucesso")
        } catch (err) {
            setError("Houve um erro ao editar o artigo, tente novamente")
        }

        setLoading(false)
    }

    return (
        <PageLayout>
            <Layout
                editIcon={true}
                editUrl="/panel/article"
                title="Editar artigo"
            >
                <main className="bg-white drop-shadow-lg h-auto w-full rounded-lg py-8 px-10 my-6 lg:my-0">
                    <form onSubmit={updateArticle}>
                        <InputGroup className="flex flex-col my-2">
                            <FormLabel>
                                <FontAwesomeIcon icon={faFolderOpen} className="mr-2" />
                                Associado a categoria
                            </FormLabel>

                            <Select
                                placeholder="Selecione qual categoria esse artigo estará associado"
                                size="sm"
                                borderRadius="6px"
                                onChange={(e) => setRelatedToCategory(e.target.value)}
                                value={relatedToCategory}
                            >
                                {category.map(item => (
                                    <option
                                        key={item._id}
                                        value={item._id}
                                    >
                                        {item.title}
                                    </option>
                                ))}
                            </Select>
                        </InputGroup>

                        {relatedToCategory ? (
                            <InputGroup className="flex flex-col my-2">
                                <FormLabel>
                                    <FontAwesomeIcon icon={faFolderTree} className="mr-2" />
                                    Associado a subcategoria
                                </FormLabel>

                                <Select
                                    placeholder="Selecione qual subcategoria esse artigo estará associado"
                                    size="sm"
                                    borderRadius="6px"
                                    onChange={(e) => setRelatedToSubcategory(e.target.value)}
                                    value={relatedToSubcategory}
                                >
                                    {subcategory.map(item => (
                                        <option
                                            key={item._id}
                                            value={item._id}
                                        >
                                            {item.title}
                                        </option>
                                    ))}
                                </Select>
                            </InputGroup>
                        ) : null}

                        <InputGroup className="flex flex-col my-2">
                            <FormLabel>
                                <FontAwesomeIcon icon={faUserGroup} className="mr-2" />
                                Usuários que poderão ver o artigo
                            </FormLabel>

                            <Select
                                placeholder="Selecione quais usuários poderão ver esse artigo"
                                size="sm"
                                borderRadius="6px"
                                onChange={(e) => setUserPermission(e.target.value)}
                                value={userPermission}
                            >
                                <option value="externo">Externo</option>
                                <option value="interno">Interno</option>
                            </Select>
                        </InputGroup>

                        {error && <ScreenAlert status="error" message={error} />}
                        {success && <ScreenAlert status="success" message={success} />}

                        <InputGroup className="flex flex-col mt-5 mr-2">
                            <FormLabel>
                                <FontAwesomeIcon icon={faHeader} className="mr-2" />
                                Título do artigo
                            </FormLabel>

                            <Input
                                type='text'
                                placeholder="Digite aqui o título do artigo"
                                size="sm"
                                onChange={(e) => setTitle(e.target.value)}
                                value={title}
                                borderRadius="6px"
                            />
                        </InputGroup>

                        <div className="mt-8">
                            <SunEditor
                                name="sun-editor"
                                placeholder="Insira o conteúdo do artigo aqui"
                                width="100%"
                                height="700px"
                                onChange={handleChange}
                                setContents={articleContent}
                                defaultValue={articleContent}
                                setOptions={{
                                    buttonList: [
                                        ["undo", "redo"],
                                        ["bold", "underline", "italic", "strike", "align", "fontSize", "formatBlock", "table", "image", "video", "audio"],
                                    ]
                                }}
                            />
                        </div>

                        <div className="flex justify-end mt-4">
                            <button
                                type="submit"
                                disabled={loading}
                                className="w-full my-2 md:my-0 px-3 py-2 bg-green-500 border-[1px] text-white font-semibold rounded-md drop-shadow-lg hover:bg-white hover:border-green-500 hover:text-green-500 transition-all ease-in-out duration-300"
                            >
                                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                Editar artigo
                            </button>
                        </div>
                    </form>
                </main>
            </Layout>
        </PageLayout>
    )
}