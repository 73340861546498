import { Link } from "react-router-dom";

export default function Subcategory({ categoryId, subcategories, setSubcategory }) {
    return (
        <div className="flex flex-col gap-y-2">
            <h2 className="text-xl text-soulog font-semibold">Selecione uma subcategoria</h2>

            <div className="flex flex-col">
                {subcategories.map(item => {
                    return (
                        <Link to={`/category/${categoryId}/subcategory/${item._id}`} key={item._id}>
                            <p
                                className="text-md py-2 px-3 hover:underline hover:text-soulog cursor-pointer"
                                onClick={() => setSubcategory({ id: item._id, title: item.title })}
                            >
                                {item.title}
                            </p>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}