import axios from 'axios';
import { Alert, AlertIcon } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useState } from 'react';
import Layout from '../../../components/Layout.component';
import CreateUser from "../../../components/Panel/CreateUser.component";
import TableView from '../../../components/Panel/Table/Table.component';

export default function User() {
    const [users, setUsers] = useState([])

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await axios.get('/api/user', { withCredentials: true })

            if(response) {
                setUsers(response.data)
            }
        }

        fetchUsers()
    }, [])

    return (
        <>
            <Layout title="Gestão de usuários">
                <section className="flex flex-col justify-between items-center xl:items-start mb-8 xl:px-0">
                    <div className="flex flex-col bg-white rounded-lg drop-shadow-lg w-full py-8 px-10 my-6 xl:my-0">
                        <h2 className="text-xl font-semibold">Criar novo usuário</h2>
                        <CreateUser />
                    </div>

                    <Alert status="info" className="rounded-md font-semibold mt-6">
                        <AlertIcon />
                        Ao deletar um usuário, não é possível recuperá-lo, tenha certeza de sua ação
                    </Alert>

                    <div className="flex justify-evenly bg-white rounded-lg drop-shadow-lg w-full h-auto py-8 px-10 mt-6">
                        <TableView users={users} />
                    </div>
                </section>
            </Layout>
        </>
    )
}