import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function NoMatch() {
    return (
        <main className="flex flex-col justify-center items-center h-[90vh]">
            <FontAwesomeIcon icon={faCircleExclamation} className="text-yellow-500 text-9xl"/>
            <h1 className="text-2xl font-semibold mt-8 mb-3">Oops, parece que você tentou acessar algo que não existe ou foi excluído.</h1>
            <h2 className="text-xl font-semibold">Clique para retornar a <Link to="/" className="underline hover:text-soulog">página inicial</Link></h2>
        </main>
    )
}