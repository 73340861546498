import axios from "axios";
import { useState, useEffect } from "react";
// Chakra UI
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR';
import { Link } from "react-router-dom";

export default function TableArticles() {
    const [articles, setArticles] = useState([])

    const handleDelete = async (articleId) => {
        const response = await axios.delete(`/api/article/${articleId}`, { withCredentials: true })

        if (response) {
            console.log(response)
        }
    }

    useEffect(() => {
        const fetchArticles = async () => {
            const response = await axios.get('/api/article', { withCredentials: true })

            if (response) {
                setArticles(response.data)
            }
        }

        fetchArticles()
    }, [articles])

    return (
        <TableContainer>
            <Table variant="striped" colorScheme="teal" className="w-[80vw]">
                <TableCaption>Visualização de todos artigos</TableCaption>

                {/* Header */}
                <Thead className="sticky">
                    <Tr>
                        <Th>Título do artigo</Th>
                        <Th>Data de criação</Th>
                        <Th>Editar</Th>
                    </Tr>
                </Thead>

                <Tbody>
                    {articles.map((item) => (
                        <Tr key={item._id}>
                            <Td>{item.title}</Td>
                            <Td>{format(new Date(item.createdAt), "dd 'de' MMMMMM 'de' yyyy", { locale: ptBR })}</Td>
                            <Td>
                                <Link to={`/article/edit/${item._id}`}>
                                    <FontAwesomeIcon icon={faEdit} className="mr-4 cursor-pointer hover:text-soulog transition duration-300 ease-in-out" />
                                </Link>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    onClick={() => handleDelete(item._id)}
                                    className="cursor-pointer hover:text-soulog transition duration-300 ease-in-out"
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}