import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../../components/Layout.component";
import PageLayout from "../../../components/PageLayout.component";
import { formatDistanceToNow } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Suneditor
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

export default function Article() {
    const [articles, setArticles] = useState([])
    const [article, setArticle] = useState([])

    let navigate = useNavigate();

    const { subcategoryId, articleId } = useParams()

    const changeArticle = (relatedToCategory, relatedToSubcategory, id) => {
        // const url = window.location.pathname.split('/').reverse().slice(1).reverse().join('/') + '/' + id
        const url = window.location.pathname.split('/').join(`/category/${relatedToCategory}/subcategory/${relatedToSubcategory}/article/${id}`)

        navigate(url)
    }

    useEffect(() => {
        const fetchArticlesFromSubcategory = async () => {
            const response = await axios.get(`/api/subcategory/${subcategoryId}/articles`, { withCredentials: true })

            if (response) {
                setArticles(response.data)
            }
        }

        const fetchArticle = async () => {
            const response = await axios.get(`/api/article/${articleId}`, { withCredentials: true })

            if (response) {
                setArticle([response.data])
                console.log(response.data.articleContent)
            }
        }

        fetchArticlesFromSubcategory()
        fetchArticle()
    }, [subcategoryId, articleId])

    return (
        <PageLayout>
            <Layout title="Artigo">
                <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-x-8">
                    <aside className="bg-white drop-shadow-lg min-h-[40vh] lg:min-h-[80vh] w-full rounded-lg py-8 px-10 col-span-1">
                        <h2 className="font-semibold text-xl mb-4">Artigos</h2>

                        {articles.map(item => (
                            <p
                                className="text-md p-2 hover:underline hover:text-soulog cursor-pointer"
                                onClick={() => changeArticle(item.relatedToCategory, item.relatedToSubcategory, item._id)}
                                key={item._id}
                            >
                                {item.title}
                            </p>
                        ))}
                    </aside>

                    <main className="bg-white drop-shadow-lg min-h-[90vh] lg:min-h-[80vh] max-h-fit w-full rounded-lg py-8 px-10 my-6 lg:my-0 col-span-3">
                        {article.map(item => (
                            <div key={item._id}>
                                <div className="flex flex-col md:flex-row justify-between items-center">
                                    <div className="flex items-center">
                                        <Link to={`/category/${item.relatedToCategory}`}>
                                            <FontAwesomeIcon icon="fa-solid fa-circle-chevron-left" className="hover:text-soulog text-xl mx-2 mt-[1px] transition duration-300 ease-in-out" />
                                        </Link>
                                        <h1 className="font-bold text-2xl">{item.title}</h1>
                                    </div>
                                    <span>{formatDistanceToNow(new Date(item.createdAt), { locale: ptBR, addSuffix: true })}</span>
                                </div>

                                <hr className="border-soulog my-4" />

                                <SunEditor
                                    disable={true}
                                    hideToolbar={true}
                                    disableToolbar={true}
                                    defaultValue={item.articleContent}
                                    height="100%"
                                    width="100%"
                                />

                                <footer className="flex flex-col items-center justify-center my-5">
                                    <div className="flex flex-col">
                                        <p className="text-center">Você achou esse artigo útil?</p>

                                        <div className="flex justify-evenly my-3">
                                            <button
                                                type="button"
                                                className={`border-[1px] border-soulog rounded-md px-[30px] py-1 text-sm text-soulog`}
                                            >
                                                Sim
                                            </button>

                                            <button type="button" className="border-[1px] border-soulog rounded-md px-[30px] py-1 text-sm text-soulog">Não</button>
                                        </div>

                                        <span className="text-center font-semibold text-sm">x pessoas não acharam esse artigo útil</span>

                                        <span className="text-center font-semibold text-sm mt-1">x pessoas acharam esse artigo útil</span>
                                    </div>
                                </footer>
                            </div>
                        ))}
                    </main>
                </div>
            </Layout>
        </PageLayout>
    )
}