import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';

import Layout from "../../../components/Layout.component";
import PageLayout from '../../../components/PageLayout.component';
import Subcategory from "../../../components/Content/Subcategory.component";
import BreadcrumbView from "../../../components/Content/BreadcrumbView.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Category() {
    const [category, setCategory] = useState({});
    const [categories, setCategories] = useState([]);

    const [subcategories, setSubcategories] = useState([]);
    const [subcategory, setSubcategory] = useState({
        id: '',
        title: ''
    });

    const [articles, setArticles] = useState([])

    const { categoryId, subcategoryId } = useParams()

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await axios.get('/api/category', { withCredentials: true })

            if (response) {
                setCategories(response.data)
            }
        }

        const fetchCategory = async () => {
            const response = await axios.get(`/api/category/${categoryId}`, { withCredentials: true })

            if (response) {
                setCategory(response.data)
            }
        }

        const fetchSubcategories = async () => {
            const response = await axios.get(`/api/category/${categoryId}/subcategories`, { withCredentials: true })

            if (response) {
                setSubcategories(response.data)
            }
        }

        const fetchArticles = async () => {
            if (!subcategoryId) return

            const response = await axios.get(`/api/subcategory/${subcategoryId}/articles`, { withCredentials: true })

            if (response) {
                setArticles(response.data)
            }
        }

        fetchCategories()
        fetchCategory()
        fetchSubcategories()
        fetchArticles()
    }, [categoryId, subcategoryId])

    return (
        <PageLayout>
            <Layout title={category.title}>
                <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-x-8">
                    <aside className="bg-white drop-shadow-lg min-h-[40vh] lg:min-h-[80vh] w-full rounded-lg py-8 px-10 col-span-1">
                        <h2 className="font-semibold text-xl mb-4">Categorias</h2>

                        {categories.map(item => (
                            <Link to={`/category/${item._id}`} key={item._id}>
                                <p
                                    className="text-md p-2 hover:underline hover:text-soulog cursor-pointer"
                                    onClick={() => {
                                        setCategory({ id: item._id, title: item.title })
                                        setSubcategory({ id: "", title: "" })
                                    }}
                                >
                                    {item.title}
                                </p>
                            </Link>
                        ))}
                    </aside>

                    <main className="bg-white drop-shadow-lg min-h-[90vh] lg:min-h-[80vh] w-full rounded-lg py-8 px-10 my-6 lg:my-0 col-span-3">
                        <BreadcrumbView
                            category={category}
                            categoryId={categoryId}
                            subcategory={subcategory}
                            subcategoryId={subcategoryId}
                        />

                        <hr className="border-soulog my-5" />

                        {/* Sucategories */}
                        <Subcategory
                            categoryId={categoryId}
                            subcategories={subcategories}
                            setSubcategory={setSubcategory}
                            articles={articles}
                        />

                        {subcategory.id ? (
                            <div className="flex flex-col mt-4 p-3">
                                <h2 className="text-sm md:text-md lg:text-lg text-soulog font-semibold">Selecione um artigo de {subcategory.title}</h2>

                                <div className="flex flex-col flex-wrap px-4">
                                    {articles.map(item => {
                                        return (
                                            <Link to={`/category/${categoryId}/subcategory/${subcategoryId}/article/${item._id}`} key={item._id}>
                                                <p
                                                    className="py-2 px-3"
                                                >
                                                    {item.title}
                                                </p>
                                            </Link>
                                        )
                                    })}
                                </div>
                            </div>
                        ) : null}
                    </main>
                </div>
            </Layout>
        </PageLayout >
    )
}