import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
} from '@chakra-ui/react'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function BreadcrumbView({ category, subcategory }) {
    return (
        <Breadcrumb
            className="font-semibold"
            spacing="6px"
            separator={<FontAwesomeIcon icon={faChevronRight} className="text-soulog" />}
        >
            <BreadcrumbItem>
                <BreadcrumbLink>{category.title}</BreadcrumbLink>
            </BreadcrumbItem>

            {subcategory ? (
                <BreadcrumbItem>
                    <BreadcrumbLink>{subcategory.title}</BreadcrumbLink>
                </BreadcrumbItem>
            ) : null}
        </Breadcrumb>
    )
}