import React from "react";
import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from "./components/ProtectedRoute";
import PrivateRoute from "./components/PrivateRoute";

// Pages
import Login from "./view/Login";
import Category from './view/Home/Category';
import Article from "./view/Home/Article";

// Panel
import Panel from './view/Panel';
import User from './view/Panel/User';
import PanelCategory from './view/Panel/Category/index';
import PanelArticle from './view/Panel/Article/index';

// Edit
import EditUser from './view/Edit/EditUser';
import EditCategory from './view/Edit/EditCategory';
import EditSubcategory from './view/Edit/EditSubcategory';
import EditArticle from './view/Edit/EditArticle';

// Not found
import NoMatch from './NoMatch';
import axios from "axios";

const Home = React.lazy(() => import("./view/Home"))
const Profile = React.lazy(() => import("./view/Profile"));

axios.defaults.baseURL = process.env.REACT_APP_AXIOS_URL

export default function App() {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />

            {/* Protected Routes */}
            <Route element={<ProtectedRoute />}>
                <Route path="/" element={(
                    <React.Suspense fallback={<div>Loading...</div>}>
                        <Home />
                    </React.Suspense>
                )} />

                <Route path="/category/:categoryId" element={<Category />} />
                <Route path="/category/:categoryId/subcategory/:subcategoryId" element={<Category />} />
                <Route path="/category/:categoryId/subcategory/:subcategoryId/article/:articleId" element={<Article />} />

                <Route path="/profile/:id" element={(
                    <React.Suspense fallback={<div>Loading...</div>}>
                        <Profile />
                    </React.Suspense>
                )} />

                <Route element={<PrivateRoute />}>
                    <Route path="/user/edit/:id" element={<EditUser />} />
                    <Route path="/category/edit/:id" element={<EditCategory />} />
                    <Route path="/subcategory/edit/:id" element={<EditSubcategory />} />
                    <Route path="/article/edit/:id" element={<EditArticle />} />

                    <Route path="/panel" element={<Panel />}>
                        <Route path="user" element={<User />} />
                        <Route path="category" element={<PanelCategory />} />
                        <Route path="article" element={<PanelArticle />} />
                    </Route>
                </Route>
            </Route>

            {/* Error page */}
            <Route path="*" element={<NoMatch />} />
        </Routes>
    );
}