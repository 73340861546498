import axios from "axios";
import { FormLabel, Input, InputGroup, Select } from "@chakra-ui/react";
import { faCheck, faFolderOpen, faFolderTree, faIcons } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ScreenAlert from "../../components/Alert/Alert.component";
import Layout from "../../components/Layout.component";
import PageLayout from "../../components/PageLayout.component";

export default function EditCategory() {
    const [title, setTitle] = useState("")
    const [relatedTo, setRelatedTo] = useState("")
    const [category, setCategory] = useState([])

    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [loading, setLoading] = useState(false)

    const { id } = useParams()

    const updateCategory = async (e) => {
        e.preventDefault()
        setError(null)

        if (!title || !relatedTo) {
            return setError("Por favor, preencha todas informações necessárias")
        }

        const subcategory = { title, relatedTo }

        const response = await axios.patch(`/api/subcategory/${id}`, subcategory, { withCredentials: true })

        try {
            setLoading(true)

            if (response) {
                setTitle("")
                setRelatedTo("")
                setError(null)
                setSuccess("Subcategoria atualizada com sucesso")
            }
        } catch (err) {
            setError("Houve um erro ao editar a subcategoria, tente novamente")
        }

        setLoading(false)
    }

    useEffect(() => {
        const fetchCategory = async () => {
            const response = await axios.get('/api/category', { withCredentials: true })

            if (response) {
                setCategory(response.data)
            }
        }

        const fetchSubcategory = async () => {
            const response = await axios.get(`/api/subcategory/${id}`, { withCredentials: true })

            if (response) {
                setTitle(response.data.title)
                setRelatedTo(response.data.relatedTo)
            }
        }

        fetchCategory()
        fetchSubcategory()
    }, [id])

    return (
        <PageLayout>
            <Layout
                editIcon={true}
                editUrl="/panel/category"
                title="Editar categoria"
            >
                <main className="bg-white drop-shadow-lg h-auto w-full rounded-lg py-8 px-10 my-6 lg:my-0">
                    <form onSubmit={updateCategory}>
                        <InputGroup className="flex flex-col my-2 mr-2">
                            <FormLabel htmlFor="category">
                                <FontAwesomeIcon icon={faFolderTree} className="mr-2" />
                                Nome da subcategoria
                            </FormLabel>

                            <Input
                                id="category"
                                type="text"
                                placeholder="Digite aqui o nome da nova categoria que deseja criar"
                                size="sm"
                                onChange={(e) => setTitle(e.target.value)}
                                value={title}
                                borderRadius="6px"
                            />
                        </InputGroup>

                        <InputGroup className="flex flex-col my-2">
                            <FormLabel>
                                <FontAwesomeIcon icon={faFolderOpen} className="mr-2" />
                                Associado a categoria
                            </FormLabel>

                            <Select
                                placeholder="Selecione o ícone que será visualizado pelos leitores"
                                size="sm"
                                onChange={(e) => setRelatedTo(e.target.value)}
                                value={relatedTo}
                                borderRadius="6px"
                            >
                                {category.map((item) => (
                                    <option
                                        key={item._id}
                                        value={item._id}
                                    >
                                        {item.title}
                                    </option>
                                ))}
                            </Select>
                        </InputGroup>

                        {error && <ScreenAlert status="error" message={error} />}
                        {success && <ScreenAlert status="success" message={success} />}

                        <div className="flex justify-end mt-5">
                            <button
                                type="submit"
                                disabled={loading}
                                className="w-full my-2 md:my-0 px-3 py-2 bg-green-500 border-[1px] text-white font-semibold rounded-md drop-shadow-lg hover:bg-white hover:border-green-500 hover:text-green-500 transition-all ease-in-out duration-300"
                            >
                                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                Atualizar subcategoria
                            </button>
                        </div>
                    </form>
                </main>
            </Layout>
        </PageLayout>
    )
}