import { useAuthUser } from "react-auth-kit"
import { Navigate, Outlet } from "react-router-dom"

export default function PrivateRoute() {
    const auth = useAuthUser()

    if(
        auth().permission === "operador" ||
        auth().permission === "gerente" ||
        auth().permission === "administrador"
    ) {
        return <Outlet/>
    } 
    
    return  <Navigate to="/"/>
}