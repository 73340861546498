import axios from "axios";
import { FormLabel, Input, InputGroup, Select } from "@chakra-ui/react";
import { faCheck, faFolderOpen, faFolderTree } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ScreenAlert from "../../Alert/Alert.component";
import { useAuthUser } from "react-auth-kit";

export default function CreateSubcategory({ category }) {
    const [title, setTitle] = useState('')
    const [relatedTo, setRelatedTo] = useState('')

    const [error, setError] = useState();
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false)

    const auth = useAuthUser()

    const createSubcategory = async (e) => {
        e.preventDefault()
        setError(null)

        if (!title || !relatedTo) {
            return setError("Por favor, preencha todas informações necessárias")
        }

        if (auth().permission === "externo" || auth().permission === "operador") {
            return setError("Você não possui permissão para criar uma subcategoria")
        }

        const subcategory = { title, relatedTo }

        const response = await axios.post('/api/subcategory', subcategory, { withCredentials: true })

        try {
            setLoading(true)

            if (response) {
                setTitle("")
                setRelatedTo("")
                setError(null)
                setSuccess("Subcategoria criada com sucesso")
            }
        } catch (err) {
            setError("Houve um erro ao criar uma nova subcategoria, tente novamente")
        }

        setLoading(false)
    }

    return (
        <div>
            <h2 className="text-xl font-semibold">Criar nova subcategoria</h2>

            <form onSubmit={createSubcategory}>
                <InputGroup className="flex flex-col my-2 mr-2">
                    <FormLabel>
                        <FontAwesomeIcon icon={faFolderTree} className="mr-2" />
                        Nome da subcategoria
                    </FormLabel>

                    <Input
                        type='text'
                        placeholder="Digite aqui o nome da nova subcategoria que deseja criar"
                        size="sm"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        borderRadius="6px"
                    />
                </InputGroup>

                <InputGroup className="flex flex-col my-2">
                    <FormLabel>
                        <FontAwesomeIcon icon={faFolderOpen} className="mr-2" />
                        Associado a categoria
                    </FormLabel>

                    <Select
                        placeholder="Selecione qual categoria essa subcategoria estará associada"
                        size="sm"
                        borderRadius="6px"
                        onChange={(e) => setRelatedTo(e.target.value)}
                        value={relatedTo}
                    >
                        {category.map((item) => (
                            <option
                                key={item._id}
                                value={item._id}
                            >
                                {item.title}
                            </option>
                        ))}
                    </Select>
                </InputGroup>

                {error && <ScreenAlert status="error" message={error} />}
                {success && <ScreenAlert status="success" message={success} />}

                <div className="flex justify-end mt-2.5">
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full my-2 md:my-0 px-3 py-2 bg-green-500 border-[1px] text-white font-semibold rounded-md drop-shadow-lg hover:bg-white hover:border-green-500 hover:text-green-500 transition-all ease-in-out duration-300"
                    >
                        <FontAwesomeIcon icon={faCheck} className="mr-2" />
                        Criar subcategoria
                    </button>
                </div>
            </form>
        </div>
    )
}