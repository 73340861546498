import axios from "axios";
import { FormLabel, InputGroup, Input } from "@chakra-ui/react";
import { faCheck, faFolderOpen, faIcons } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ScreenAlert from "../../Alert/Alert.component";
import { useAuthUser } from "react-auth-kit";

export default function CreateCategory() {
    const [title, setTitle] = useState("")
    const [icon, setIcon] = useState("")

    const [error, setError] = useState();
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false)

    const auth = useAuthUser()

    const createCategory = async (e) => {
        e.preventDefault()
        setError(null)

        if (!title || !icon) {
            return setError("Por favor, preencha todas informações necessárias")
        }

        if (auth().permission === "externo" || auth().permission === "operador") {
            return setError("Você não possui permissão para criar uma categoria")
        }

        const formData = new FormData()
        formData.append("title", title)
        formData.append("icon", icon)

        const response = await axios.post('/api/category', formData, { withCredentials: true })

        try {
            setLoading(true)

            if (response) {
                setTitle(null)
                setIcon(null)
                setError(null)
                setSuccess("Categoria criada com sucesso")
            }
        } catch (err) {
            setError("Houve um erro ao criar uma nova categoria, tente novamente")
        }

        setLoading(false)
    }

    return (
        <div>
            <h2 className="text-xl font-semibold">Criar nova categoria</h2>

            <form onSubmit={createCategory} encType="multipart/form-data">
                <InputGroup className="flex flex-col my-2 mr-2">
                    <FormLabel htmlFor="category">
                        <FontAwesomeIcon icon={faFolderOpen} className="mr-2" />
                        Nome da categoria
                    </FormLabel>

                    <Input
                        id="category"
                        type="text"
                        placeholder="Digite aqui o nome da nova categoria que deseja criar"
                        size="sm"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        borderRadius="6px"
                    />
                </InputGroup>

                <InputGroup className="flex flex-col my-2">
                    <FormLabel>
                        <FontAwesomeIcon icon={faIcons} className="mr-2" />
                        Ícone
                    </FormLabel>

                    <input
                        type="file"
                        name="icon"
                        value={undefined}
                        onChange={(e) => setIcon(e.target.files[0])}
                    />
                </InputGroup>

                {error && <ScreenAlert status="error" message={error} />}
                {success && <ScreenAlert status="success" message={success} />}

                <div className="flex justify-end mt-2.5">
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full my-2 md:my-0 px-3 py-2 bg-green-500 border-[1px] text-white font-semibold rounded-md drop-shadow-lg hover:bg-white hover:border-green-500 hover:text-green-500 transition-all ease-in-out duration-300"
                    >
                        <FontAwesomeIcon icon={faCheck} className="mr-2" />
                        Criar categoria
                    </button>
                </div>
            </form>
        </div>
    )
}