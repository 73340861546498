import axios from "axios";
import { Alert, AlertIcon } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Layout from '../../../components/Layout.component';
import CreateCategory from "../../../components/Panel/CreateCategoryAndSubcategory/CreateCategory.component";
import CreateSubcategory from "../../../components/Panel/CreateCategoryAndSubcategory/CreateSubcategory.component";
import TableCategoryView from "../../../components/Panel/Table/TableCategory.component";
import TableSubcategoryView from '../../../components/Panel/Table/TableSubcategory.component';

export default function PanelCategory() {
    const [category, setCategory] = useState([]);
    const [subcategory, setSubcategory] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await axios.get('/api/category', { withCredentials: true })

            if(response) {
                setCategory(response.data)
            }
        }

        const fetchSubcategories = async () => {
            const response = await axios.get('/api/subcategory', { withCredentials: true })

            if(response) {
                setSubcategory(response.data)
            }
        }

        fetchCategories()
        fetchSubcategories()
    }, [subcategory])

    return (
        <>
            <Layout title="Gestão de categorias">
                <section className="flex flex-col justify-between items-center xl:items-start mb-8 xl:px-0">
                    <div className="flex flex-col justify-between bg-white rounded-lg drop-shadow-lg w-full h-auto py-8 px-10 my-6 xl:my-0">
                        <CreateCategory />

                        <hr className="my-6"/>

                        <CreateSubcategory category={category} />
                    </div>

                    <Alert status="info" className="rounded-md font-semibold mt-6">
                        <AlertIcon />
                        Ao deletar uma categoria e/ou subcategoria, não é possível recuperá-lo, tenha certeza de sua ação
                    </Alert>

                    <div className="flex flex-col 2xl:flex-row w-full gap-x-4 mt-6">
                        <div className="flex justify-evenly bg-white rounded-lg drop-shadow-lg w-full h-auto py-8 px-6">
                            <TableCategoryView  category={category}/>
                        </div>

                        <div className="flex justify-evenly bg-white rounded-lg drop-shadow-lg w-full h-auto py-8 px-6">
                            <TableSubcategoryView subcategory={subcategory} />
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}