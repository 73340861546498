import axios from "axios";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Chakra UI
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";

export default function TableView() {
    const [users, setUsers] = useState([])
    const auth = useAuthUser()

    const handleDelete = async (userId) => {
        const response = await axios.delete(`/api/user/${userId}`, { withCredentials: true })

        if (response) {
            console.log(response)
        }
    }

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await axios.get('/api/user', { withCredentials: true })

            if (response) {
                setUsers(response.data)
            }
        }

        fetchUsers()
    }, [users])

    return (
        <form>
            <TableContainer>
                <Table variant='striped' colorScheme='teal'>
                    <TableCaption>Visualização de todos os usuários</TableCaption>

                    {/* Header */}
                    <Thead>
                        <Tr>
                            <Th>Usuário</Th>
                            <Th>Email</Th>
                            <Th>Permissão</Th>
                            <Th>Editar</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {users.map((item) => (
                            <Tr key={item._id}>
                                <Td>{item.name}</Td>
                                <Td>{item.email}</Td>
                                <Td>{item.permission.charAt(0).toUpperCase() + item.permission.slice(1)}</Td>
                                <Td>
                                    {
                                        auth()._id === item._id ||
                                            auth().permission === item.permission
                                            ? (
                                                <FontAwesomeIcon icon={faEdit} className="text-lg mr-8 text-red-600" />
                                            ) : (
                                                <Link to={`/user/edit/${item._id}`}>
                                                    <FontAwesomeIcon
                                                        icon={faEdit}
                                                        className="text-lg mr-8 cursor-pointer hover:text-soulog transition duration-300 ease-in-out"
                                                    />
                                                </Link>
                                            )}

                                    {
                                        auth()._id === item._id ||
                                            auth().permission === item.permission
                                            ? (
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    className="text-lg text-red-600"
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    onClick={() => handleDelete(item._id)}
                                                    className="text-lg cursor-pointer hover:text-soulog transition duration-300 ease-in-out"
                                                />
                                            )}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </form >
    )
}