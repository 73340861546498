import axios from "axios";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Chakra UI
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Link } from 'react-router-dom';

export default function TableSubcategoryView({ subcategory }) {
    const handleDelete = async (subcategoryId) => {
        const response = await axios.delete(`/api/subcategory/${subcategoryId}`, { withCredentials: true})

        if (response) {
            console.log(response)
        }
    }

    return (
        <TableContainer>
            <Table variant='striped' colorScheme='teal'>
                <TableCaption>Visualização de todas subcategorias</TableCaption>

                {/* Header */}
                <Thead>
                    <Tr>
                        <Th>Título da categoria</Th>
                        <Th>Data de criação</Th>
                        <Th>Editar</Th>
                    </Tr>
                </Thead>

                <Tbody>
                    {subcategory.map((item) => (
                        <Tr key={item._id}>
                            <Td>{item.title}</Td>
                            <Td>{format(new Date(item.createdAt), "dd 'de' MMMMMM 'de' yyyy", { locale: ptBR })}</Td>
                            <Td>
                                <Link to={`/subcategory/edit/${item._id}`}>
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        className="text-lg mr-8 cursor-pointer hover:text-soulog transition duration-300 ease-in-out"
                                    />
                                </Link>

                                <FontAwesomeIcon 
                                    icon={faTrash}
                                    onClick={() => handleDelete(item._id)}
                                    className="cursor-pointer hover:text-soulog transition duration-300 ease-in-out" 
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}