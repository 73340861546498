import axios from "axios";
import { useEffect, useState } from 'react';
import Layout from '../../components/Layout.component';
import Card from '../../components/Card.component';
import { faUsers, faNewspaper, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { Link, Outlet } from "react-router-dom";
import PageLayout from '../../components/PageLayout.component';

export default function Panel() {
    const [userCount, setUserCount] = useState()
    const [categoryCount, setCategoryCount] = useState()
    const [articleCount, setArticleCount] = useState()

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await axios.get('/api/user', { withCredentials: true  })

            if (response) {
                setUserCount(response.data.length)
            }
        }

        const fetchCategories = async () => {
            const response = await axios.get('/api/category', { withCredentials: true })

            if (response) {
                setCategoryCount(response.data.length)
            }
        }

        const fetchArticles = async () => {
            const response = await axios.get('/api/article', { withCredentials: true })

            if (response) {
                setArticleCount(response.data.length)
            }
        }

        fetchUsers()
        fetchCategories()
        fetchArticles()
    }, [userCount, categoryCount, articleCount])

    return (
        <PageLayout>
            <Layout title="Painel Administrativo">

                {/* Painel de informações */}
                <section className="flex flex-col xl:flex-row justify-between items-center mb-8">
                    <div className="flex justify-evenly bg-white rounded-lg drop-shadow-lg w-full h-[190px] py-8 xl:mr-8 2xl:mr-20">
                        <SystemData dataNumber={userCount} dataTitle="Usuários" />

                        <SystemData dataNumber={categoryCount} dataTitle="Categorias" />

                        <SystemData dataNumber={articleCount} dataTitle="Artigos" />
                    </div>

                    <div className="flex flex-col md:flex-row flex-wrap md:flex-nowrap justify-center mt-4 xl:mt-0">
                        <Link to="user">
                            <Card
                                cardType="panelView"
                                title="Gestão de usuários"
                                icon={faUsers}
                                panel={true}
                                margin="my-5 mx-3 xl:ml-4"
                            />
                        </Link>

                        <Link to="category">
                            <Card
                                cardType="panelView"
                                title="Gestão de categorias"
                                icon={faNewspaper}
                                panel={true}
                                margin="my-5 mx-3 xl:ml-4"
                            />
                        </Link>

                        <Link to="article">
                            <Card
                                cardType="panelView"
                                title="Gestão de artigos"
                                icon={faFolderOpen}
                                panel={true}
                                margin="my-5 mx-3 xl:ml-4"
                            />
                        </Link>
                    </div>
                </section>
            </Layout>

            <Outlet />
        </PageLayout>
    )
}

export function SystemData({ dataNumber, dataTitle }) {
    return (
        <div className="flex flex-col justify-center items-center no-select">
            <span className="font-bold text-4xl xl:text-6xl">{dataNumber}</span>
            <h2 className="font-semibold text-md xl:text-xl">{dataTitle}</h2>
        </div>
    )
}