import {
    Alert,
    AlertIcon,
} from '@chakra-ui/react'
import Layout from '../../../components/Layout.component';
import CreateArticle from '../../../components/Panel/CreateArticle.component';
import TableArticles from '../../../components/Panel/Table/TableArticles.component';

export default function PanelArticle() {
    return (
        <Layout title="Gestão de artigos">
            <section className="flex flex-col justify-between items-center xl:items-start mb-8 xl:px-0">
                <div className="flex justify-evenly bg-white rounded-lg drop-shadow-lg w-full h-auto py-8 px-10 mt-10">
                    <CreateArticle />
                </div>

                <Alert status="info" className="rounded-md font-semibold mt-6">
                    <AlertIcon />
                    Ao deletar um artigo, não é possível recuperá-lo, tenha certeza de sua ação
                </Alert>

                <div className="flex justify-evenly bg-white rounded-lg drop-shadow-lg w-full mt-6 py-8 px-10">
                    <TableArticles />
                </div>
            </section>
        </Layout>
    )
}