import axios from "axios";
import { useState, useEffect } from "react";
import { FormLabel, Input, InputGroup, Select } from "@chakra-ui/react";
import { faCheck, faFolderOpen, faFolderTree, faHeader, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScreenAlert from "../Alert/Alert.component";
import { useAuthUser } from "react-auth-kit";

// SunEditor
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

export default function CreateArticle() {
    const [category, setCategory] = useState([]);
    const [subcategory, setSubcategory] = useState([]);

    const [title, setTitle] = useState('');
    const [relatedToCategory, setRelatedToCategory] = useState('')
    const [relatedToSubcategory, setRelatedToSubcategory] = useState('')
    const [userPermission, setUserPermission] = useState('');
    const [articleContent, setArticleContent] = useState('');

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const auth = useAuthUser()

    const handleChange = (content) => {
        setArticleContent(content)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)

        if (!title || !relatedToCategory || !relatedToSubcategory || !userPermission || !articleContent) {
            return setError("Por favor, preencha todas informações necessárias")
        }

        if (auth().permission === "externo" || auth().permission === "operador") {
            return setError("Você não possui permissão para criar um artigo")
        }

        const article = { title, relatedToCategory, relatedToSubcategory, userPermission, articleContent }

        const response = await axios.post('/api/article', article, { withCredentials: true })

        try {
            setLoading(true)

            if (response) {
                setTitle("")
                setRelatedToCategory("")
                setRelatedToSubcategory("")
                setUserPermission("")
                setArticleContent("")
                setSuccess("Artigo publicado com sucesso")
            }
        } catch (err) {
            setError("Houve um erro ao publicar seu artigo, tente novamente")
        }

        setLoading(false)
    }

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await axios.get('/api/category', { withCredentials: true })

            if (response) {
                setCategory(response.data)
            }
        }

        const fetchSubcategories = async () => {
            if (!relatedToCategory) return

            const response = await axios.get(`/api/category/${relatedToCategory}/subcategories`, { withCredentials: true })

            if (response) {
                setSubcategory(response.data)
            }
        }


        fetchCategories()
        fetchSubcategories()
    }, [relatedToCategory])

    return (
        <div className="flex flex-col w-full">
            <h2 className="text-xl font-semibold">Criar novo artigo</h2>

            <form onSubmit={handleSubmit}>
                <InputGroup className="flex flex-col my-2">
                    <FormLabel>
                        <FontAwesomeIcon icon={faFolderOpen} className="mr-2" />
                        Associado a categoria
                    </FormLabel>

                    <Select
                        placeholder="Selecione qual categoria esse artigo estará associado"
                        size="sm"
                        borderRadius="6px"
                        onChange={(e) => setRelatedToCategory(e.target.value)}
                        value={relatedToCategory}
                    >
                        {category.map(item => (
                            <option
                                key={item._id}
                                value={item._id}
                            >
                                {item.title}
                            </option>
                        ))}
                    </Select>
                </InputGroup>

                {relatedToCategory ? (
                    <InputGroup className="flex flex-col my-2">
                        <FormLabel>
                            <FontAwesomeIcon icon={faFolderTree} className="mr-2" />
                            Associado a subcategoria
                        </FormLabel>

                        <Select
                            placeholder="Selecione qual subcategoria esse artigo estará associado"
                            size="sm"
                            borderRadius="6px"
                            onChange={(e) => setRelatedToSubcategory(e.target.value)}
                            value={relatedToSubcategory}
                        >
                            {subcategory.map(item => (
                                <option
                                    key={item._id}
                                    value={item._id}
                                >
                                    {item.title}
                                </option>
                            ))}
                        </Select>
                    </InputGroup>
                ) : null}

                <InputGroup className="flex flex-col my-2">
                    <FormLabel>
                        <FontAwesomeIcon icon={faUserGroup} className="mr-2" />
                        Usuários que poderão ver o artigo
                    </FormLabel>

                    <Select
                        placeholder="Selecione quais usuários poderão ver esse artigo"
                        size="sm"
                        borderRadius="6px"
                        onChange={(e) => setUserPermission(e.target.value)}
                        value={userPermission}
                    >
                        <option value="externo">Externo</option>
                        <option value="interno">Interno</option>
                    </Select>
                </InputGroup>

                {error && <ScreenAlert status="error" message={error} />}
                {success && <ScreenAlert status="success" message={success} />}

                <InputGroup className="flex flex-col mt-5 mr-2">
                    <FormLabel>
                        <FontAwesomeIcon icon={faHeader} className="mr-2" />
                        Título do artigo
                    </FormLabel>

                    <Input
                        type='text'
                        placeholder="Digite aqui o título do artigo"
                        size="sm"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        borderRadius="6px"
                    />
                </InputGroup>

                <div className="mt-8">
                    <SunEditor
                        name="sun-editor"
                        placeholder="Insira o conteúdo do artigo aqui"
                        width="100%"
                        height="700px"
                        onChange={handleChange}
                        setOptions={{
                            buttonList: [
                                ["undo", "redo"],
                                ["bold", "underline", "italic", "strike", "align", "fontSize", "formatBlock", "table", "image", "video", "audio"],
                            ]
                        }}
                    />
                </div>

                <div className="flex justify-end mt-4">
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full my-2 md:my-0 px-3 py-2 bg-green-500 border-[1px] text-white font-semibold rounded-md drop-shadow-lg hover:bg-white hover:border-green-500 hover:text-green-500 transition-all ease-in-out duration-300"
                    >
                        <FontAwesomeIcon icon={faCheck} className="mr-2" />
                        Criar artigo
                    </button>
                </div>
            </form>
        </div>
    )
}