import axios from "axios";
import { FormLabel, Input, InputGroup } from "@chakra-ui/react";
import { faCheck, faFolderOpen, faIcons } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ScreenAlert from "../../components/Alert/Alert.component";
import Layout from "../../components/Layout.component";
import PageLayout from "../../components/PageLayout.component";

export default function EditCategory() {
    const [title, setTitle] = useState("")
    const [icon, setIcon] = useState("")

    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [loading, setLoading] = useState(false)

    const { id } = useParams()

    const updateCategory = async (e) => {
        e.preventDefault()
        setError(null)

        if (!title || !icon) {
            return setError("Por favor, preencha todas informações necessárias")
        }

        const formData = new FormData()
        formData.append("title", title)
        formData.append("icon", icon)

        const response = await axios.patch(`/api/category/${id}`, formData, { withCredentials: true })

        try {
            setLoading(true)

            if (response) {
                setTitle("")
                setIcon("")
                setError(null)
                setSuccess("Categoria atualizada com sucesso")
            }
        } catch (err) {
            setError("Houve um erro ao editar a categoria, tente novamente")
        }

        setLoading(false)
    }

    const fetchCategory = async () => {
        const response = await axios.get(`/api/category/${id}`, { withCredentials: true })

        if (response) {
            setTitle(response.data.title)
        }
    }

    fetchCategory()

    return (
        <PageLayout>
            <Layout
                editIcon={true}
                editUrl="/panel/category"
                title="Editar categoria"
            >
                <main className="bg-white drop-shadow-lg h-auto w-full rounded-lg py-8 px-10 my-6 lg:my-0">
                    <form onSubmit={updateCategory} encType="multipart/form-data">
                        <InputGroup className="flex flex-col my-2 mr-2">
                            <FormLabel htmlFor="category">
                                <FontAwesomeIcon icon={faFolderOpen} className="mr-2" />
                                Nome da categoria
                            </FormLabel>

                            <Input
                                id="category"
                                type="text"
                                placeholder="Digite aqui o nome da nova categoria que deseja criar"
                                size="sm"
                                onChange={(e) => setTitle(e.target.value)}
                                value={title}
                                borderRadius="6px"
                            />
                        </InputGroup>

                        <InputGroup className="flex flex-col my-2">
                            <FormLabel>
                                <FontAwesomeIcon icon={faIcons} className="mr-2" />
                                Ícone
                            </FormLabel>

                            <input
                                type="file"
                                name="icon"
                                onChange={(e) => setIcon(e.target.files[0])}
                                value={undefined}
                            />
                        </InputGroup>

                        {error && <ScreenAlert status="error" message={error} />}
                        {success && <ScreenAlert status="success" message={success} />}

                        <div className="flex justify-end mt-5">
                            <button
                                type="submit"
                                disabled={loading}
                                className="w-full my-2 md:my-0 px-3 py-2 bg-green-500 border-[1px] text-white font-semibold rounded-md drop-shadow-lg hover:bg-white hover:border-green-500 hover:text-green-500 transition-all ease-in-out duration-300"
                            >
                                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                Atualizar categoria
                            </button>
                        </div>
                    </form>
                </main>
            </Layout>
        </PageLayout>
    )
}