import {
    Alert,
    AlertIcon,
} from '@chakra-ui/react'

export default function ScreenAlert({ status, variant = "solid", message }) {
    return (
        <Alert status={status} variant={variant} className="my-3 rounded-lg font-semibold">
            <AlertIcon />
            {message}
        </Alert>
    )
}