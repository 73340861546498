import { useState, useRef, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Logo from '../assets/soulog_white_extended_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faGear, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { useAuthUser, useSignOut } from 'react-auth-kit';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import axios from 'axios';

export default function Menu() {
    const menuStyle = "flex items-center gap-x-2 font-semibold text-lg text-soulog hover:bg-gray-100 px-4 py-1 rounded-lg transition duration-300 ease cursor-pointer";

    const [open, setOpen] = useState(false)
    const [query, setQuery] = useState("")
    const [articlesQuery, setArticlesQuery] = useState([])
    const menuRef = useRef(null)

    const navigate = useNavigate()
    const signOut = useSignOut()
    const auth = useAuthUser()

    const handleLogout = async () => {
        signOut()
        navigate('/login')
    }

    const location = window.location.hash

    useEffect(() => {
        function handleClickOutside(e) {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setOpen(false)
            }
        }

        const fetchArticlesQuery = async () => {
            const response = await axios.get('/api/article', { withCredentials: true })

            if (response.status === 200) {
                setArticlesQuery(response.data)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        fetchArticlesQuery()

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [menuRef])

    return (
        <nav className="sticky top-0 bg-[white] drop-shadow-xl p-4 z-50">
            <div className="flex justify-between xl:px-20">
                <Link to="/">
                    <img src={Logo} className="h-12 md:h-auto" alt="Flowbite Logo" />
                </Link>

                {/* Search Bar */}
                {location === '#/'
                    ? null
                    : (
                        <div className="relative hidden lg:flex flex-col items-center w-full">
                            <div className="relative flex justify-center items-center w-full">
                                <label htmlFor="default-search" className="mb-2 text-sm font-medium sr-only">Search</label>
                                <div className="relative w-3/4 my-1">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg
                                            aria-hidden="true"
                                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z">
                                            </path>
                                        </svg>
                                    </div>
                                    <input
                                        type="search"
                                        id="default-search"
                                        className="block w-full p-4 pl-10 text-sm border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 outline-1 outline-blue-500"
                                        placeholder="Pesquise aqui um artigo por palavras-chaves"
                                        autoComplete="off"
                                        onChange={(e) => setQuery(e.target.value)}
                                    />
                                </div>
                            </div>

                            {query.length > 0 && articlesQuery.length > 0 ? (
                                <ul className="absolute top-16 bg-white drop-shadow-lg rounded-md w-3/4 p-4 lg:p-2 z-40">
                                    {articlesQuery
                                        .filter((article) =>
                                            article.title.toLowerCase().includes(query.toLowerCase()) ||
                                            article.articleContent.toLowerCase().includes(query.toLowerCase())
                                        ).map((article) => (
                                            <Link to={`category/${article.relatedToCategory}/subcategory/${article.relatedToSubcategory}/article/${article._id}`} key={article._id}>
                                                <h3 className="font-semibold text-md px-3 py-3 lg:py-1.5 hover:bg-gray-200 hover:text-soulog rounded-md transition duration-300 ease-in-out">
                                                    <FontAwesomeIcon icon={faFolder} className="mr-2 text-soulog" />
                                                    {article.title}
                                                </h3>
                                            </Link>
                                        ))}
                                </ul>
                            ) : null}
                        </div>
                    )}

                {/* Search Bar */}
                <div className="flex items-center gap-x-3">
                    <h3 className="hidden md:block text-lg text-soulog font-semibold w-44">Olá, {auth().name}</h3>

                    <FontAwesomeIcon
                        icon={faBars}
                        className="p-3 md:p-4 border-2 border-soulog bg-soulog text-white hover:bg-white hover:text-soulog 
                        cursor-pointer rounded-full transition duration-300 ease-in-out"
                        onClick={() => setOpen(prevState => !prevState)}
                    />
                </div>

                {/* Action Menu */}

                {open ? (
                    <div className="absolute right-16 top-5 flex flex-col gap-y-2 bg-white drop-shadow-xl rounded-md px-4 py-3 z-50" ref={menuRef}>
                        <NavLink to={`/profile/${auth()._id}`} className={menuStyle}>
                            <FontAwesomeIcon icon={faUser} />
                            Perfil do usuário
                        </NavLink>

                        {auth().permission === "administrador" ||
                            auth().permission === "gerente" ||
                            auth().permission === "operador" ? (
                            <Link to="/panel" className={menuStyle}>
                                <FontAwesomeIcon icon={faGear} />
                                Painel
                            </Link>
                        ) : null}

                        <div
                            className={menuStyle}
                            onClick={handleLogout}
                        >
                            <FontAwesomeIcon icon={faRightFromBracket} />
                            Sair
                        </div>
                    </div>
                ) : null}
            </div>
        </nav>
    )
}