import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function Layout({ icon, editIcon, editUrl, title, children }) {
    return (
        <section className="px-8 md:px-10 xl:px-20">
            <div className="flex flex-col pb-2">
                {editIcon ? (
                    <Link to={editUrl} className="flex items-center hover:text-soulog text-xl py-6 transition duration-300 ease-in-out">
                        <FontAwesomeIcon icon="fa-solid fa-circle-chevron-left"/>

                        <h1 className="text-3xl font-bold text-center lg:text-left mx-2 mt-1">
                            {title}
                        </h1>
                    </Link>
                ) : (
                    <h1 className="text-3xl font-bold py-6 text-center lg:text-left">
                        {icon ? <FontAwesomeIcon icon={icon} className="mr-3 text-soulog" /> : null}

                        {title}
                    </h1>
                )}
            </div>

            {children}
        </section>
    )
}