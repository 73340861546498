import axios from "axios";
import {
    Input,
    InputRightElement,
    InputGroup,
    FormControl,
    FormLabel,
    Select
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEye, faEyeSlash, faEnvelope, faLock, faCircleInfo, faBuilding, faBriefcase, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import ScreenAlert from '../Alert/Alert.component';
import { useAuthUser } from "react-auth-kit";

export default function CreateUser() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [permission, setPermission] = useState('')
    const [company, setCompany] = useState('')
    const [cnpj, setCnpj] = useState('')

    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const auth = useAuthUser()

    async function handleSubmit(e) {
        e.preventDefault();
        setError(null)

        if (password !== confirmPassword) {
            return setError("Senhas não coincidem")
        }

        if (auth().permission === "externo" || auth().permission === "operador") {
            return setError("Você não possui permissão para criar uma categoria")
        }

        const newUser = { name, email, password, permission, company, cnpj }

        const response = await axios.post('/api/user/signup', newUser, { withCredentials: true })

        try {
            setLoading(true)

            if(response) {
                setName("")
                setEmail("")
                setPassword("")
                setConfirmPassword("")
                setPermission("")
                setCompany("")
                setCnpj("")
                setSuccess("Usuário criado com sucesso")
            }
        } catch (err) {
            setError("Houve um erro ao criar um novo usuário, tente novamente")
        }

        setLoading(false)
    }

    return (
        <form onSubmit={handleSubmit}>
            <FormControl mt={3}>
                <div className="flex flex-col md:flex-row">
                    <InputGroup className="flex flex-col my-2 mr-2">
                        <FormLabel>
                            <FontAwesomeIcon icon={faUser} className="mr-2" />
                            Nome
                        </FormLabel>

                        <Input
                            type='text'
                            placeholder="Digite aqui o nome do usuário"
                            size="sm"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            borderRadius="6px"
                        />
                    </InputGroup>

                    <InputGroup className="flex flex-col my-2 lg:ml-2">
                        <FormLabel>
                            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                            Email
                        </FormLabel>
                        <Input
                            type='email'
                            placeholder="Digite aqui o email do usuário"
                            size="sm"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            borderRadius="6px"
                        />
                    </InputGroup>
                </div>

                <div className="flex flex-col md:flex-row">
                    <InputGroup className="flex flex-col my-2 mr-2">
                        <FormLabel>
                            <FontAwesomeIcon icon={faLock} className="mr-2" />
                            Senha
                        </FormLabel>

                        <InputGroup>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                pr="4.5rem"
                                size="sm"
                                placeholder="Digite aqui a senha do usuário"
                                borderRadius="6px"
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                            <InputRightElement width='2.5rem' height='2rem'>
                                <button type="button" onClick={() => setShowPassword((prevState) => !prevState)}>
                                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                                </button>
                            </InputRightElement>
                        </InputGroup>
                    </InputGroup>

                    <InputGroup className="flex flex-col my-2 lg:ml-2">
                        <FormLabel>
                            <FontAwesomeIcon icon={faLock} className="mr-2" />
                            Confirmar senha
                        </FormLabel>

                        <Input
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Confirme aqui a senha do usuário"
                            size="sm"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                            borderRadius="6px"
                        />
                    </InputGroup>
                </div>

                {error && <ScreenAlert status="error" message={error} />}
                {success && <ScreenAlert status="success" message={success} />}

                <InputGroup className="flex flex-col my-2">
                    <FormLabel>
                        <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
                        Nível de acesso
                    </FormLabel>

                    <Select placeholder="Selecione o nível de permissão do usuário" size="sm" onChange={(e) => setPermission(e.target.value)} value={permission} borderRadius="6px">
                        <option value="externo">Externo (Cliente)</option>
                        <option value="operador">Operador</option>
                        <option value="gerente">Gerente</option>
                        <option value="administrador">Administrador</option>
                    </Select>
                </InputGroup>

                {permission === "externo" && (
                    <div className="flex flex-col md:flex-row overflow-hidden">
                        <InputGroup className="flex flex-col my-2 mr-2">
                            <FormLabel>
                                <FontAwesomeIcon icon={faBuilding} className="mr-2" />
                                Empresa
                            </FormLabel>

                            <Input
                                type='text'
                                placeholder="Digite o nome da empresa do usuário externo"
                                size="sm"
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                            />
                        </InputGroup>

                        <InputGroup className="flex flex-col my-2 lg:ml-2">
                            <FormLabel>
                                <FontAwesomeIcon icon={faCircleInfo} className="mr-2" />
                                CNPJ
                            </FormLabel>

                            <Input
                                type="number"
                                placeholder="Digite o CNPJ da empresa do usuário externo"
                                size="sm"
                                onChange={(e) => setCnpj(e.target.value)}
                                value={cnpj}
                            />
                        </InputGroup>
                    </div>
                )}

                <div className="flex flex-col md:flex-row justify-center md:justify-end mt-4">
                    <button
                        type="submit"
                        disabled={loading}
                        className="my-2 lg:ml-3 md:my-0 px-3 py-2 bg-green-500 border-[1px] text-white font-semibold rounded-md drop-shadow-lg hover:bg-white hover:border-green-500 hover:text-green-500 transition-all ease-in-out duration-300"
                    >
                        <FontAwesomeIcon icon={faCheck} className="mr-2" />
                        Criar usuário
                    </button>
                </div>
            </FormControl>
        </form>
    )
}